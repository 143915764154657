define('entities/webshop/products',[
  'app',
  'backbone',
  'settings',
  'underscore',
  'backboneRelational'
], function (
  App,
  Backbone,
  Settings,
  _
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  //-----------------

  app.ns.WebshopProduct = Backbone.RelationalModel.extend({
    idAttribute: 'code',
    fetch: function () {
      var params = {
        locale: Settings.get('lang')
      };

      this.url = Settings.url('compuzz2', 'webshop/product/' + this.getCode() + '/get', params);
      return Backbone.Model.prototype.fetch.call(this);
    },

    parse: function (response, options) {
      if (response)
        API.create(response);

      return Backbone.Model.prototype.parse.call(this, response, options);
    },

    getId: function () {
      return parseInt(this.get('id'), 10);
    },

    getCode: function () {
      return this.get('code');
    },

    getStatus: function () {
      return this.get('status');
    },

    setStatus: function (status) {
      var defer = $.Deferred();

      if (this.getStatus() === status) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        code: this.getCode(),
        status: status
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/status/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    getDefaultCategory: function () {
      return parseInt(this.get('defaultCategory'), 10);
    },

    setDefaultCategory: function (id) {
      var defer = $.Deferred();

      if (this.getDefaultCategory() === id) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productId: this.getId(),
        categoryId: id
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/categories/default/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    addCategory: function (id) {
      var defer = $.Deferred();

      var data = {
        productIds: [this.getId()],
        categoryId: id
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/products/add'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    deleteCategory: function (id) {
      var defer = $.Deferred();

      var data = {
        productId: this.getId(),
        categoryId: id
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/category/products/delete'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseJSON.message);
        }
      });
      return defer.promise();
    },

    getPosition: function () {
      return this.get('position');
    },

    getLabel: function () {
      return this.get('label');
    },

    setLabel: function (value) {
      var defer = $.Deferred();

      if (this.getLabel() === value) {
        defer.resolve();
        return defer.promise();
      }

      this.update('label/update', value).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));
    },

    getYoutubeVideo: function () {
      return this.getText('youtube-video', 'fr');
    },

    setYoutubeVideo: function (value) {
      return this.setText('youtube-video', 'fr', value);
    },

    update: function(url, value) {
      var defer = $.Deferred();
      var data = {
        id: this.getId(),
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/' + url),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    },

    clearCache: function () {
      var defer = $.Deferred();
      var data = {
        id: this.getId()
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/clean-cache'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    },

    getName: function (lang) {
      var name = this.getText('name', lang);
      if (name === '')
        name = this.get('label');
      return name;
    },

    setName: function (lang, value) {
      return this.setText('name', lang, value);
    },

    getDescription: function (lang) {
      return this.getText('long', lang);
    },

    setDescription: function (lang, value) {
      return this.setText('long', lang, value);
    },

    getExtraDescription: function (lang) {
      return this.getText('extra-description', lang);
    },

    setExtraDescription: function (lang, value) {
      return this.setText('extra-description', lang, value);
    },

    getMetaTitle: function (lang) {
      return this.getText('meta-title', lang);
    },

    setMetaTitle: function (lang, value) {
      return this.setText('meta-title', lang, value);
    },

    getMetaDescription: function (lang) {
      return this.getText('meta-description', lang);
    },

    setMetaDescription: function (lang, value) {
      return this.setText('meta-description', lang, value);
    },

    getSlug: function (lang) {
      if (lang)
        return this.getText('url', lang);
      else
        return this.getText('url');
    },

    getIndex: function (lang) {
      var result = false;

      _.each(this.get('properties'), _.bind(function (p) {
        if (p.type === 'noindex') {
          var object = JSON.parse(p.value);
          if (typeof object === 'object') {
            if(object[lang] === true)
              result = false;
            if (object[lang] === false)
              result = true;
          }
        }
      }, this));

      return result;
    },

    setIndex: function (lang, value) {
      var defer = $.Deferred();

      if (this.getIndex(lang) === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productId: this.getId(),
        lang: lang,
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/seo/index/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    },

    getSupplierCode: function () {
      var result = true;
      _.each(this.get('properties'), _.bind(function (p) {
        if (p.type === 'supplier-code')
          result = p.value;
      }, this));

      return result;
    },

    getSupplierName: function () {
      var result = true;
      _.each(this.get('properties'), _.bind(function (p) {
        if (p.type === 'supplier-name')
          result = p.value;
      }, this));

      return result;
    },

    setSlug: function (lang, value) {
      var defer = $.Deferred();

      if (this.getSlug() === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productCode: this.getCode(),
        lang: lang,
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/slug/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    },

    getText: function (type, lang) {
      if (!lang)
        lang = Settings.get('lang');

      var text = '';
      if (this.get('texts')) {
        _.each(this.get('texts'), _.bind(function (t) {
          if (t.type === type && t.lang === lang)
            text = t.content;
        }, this));
      }
      return text;
    },

    setText: function (type, lang, value) {
      var defer = $.Deferred();

      if (this.getText(type, lang) === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productCode: this.getCode(),
        code: type,
        lang: lang,
        content: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/text/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (error) {
          defer.reject(error);
        }
      });
      return defer.promise();
    },

    getPreviewImageUrl: function () {
      var url = Settings.get('notFoundImage');
      if (this.get('medias')) {
        _.each(this.get('medias'), _.bind(function (media) {
          if (media.type === 'default')
            url = Settings.configValue('webshop.imagesUrl') + '/' + media.previewUrl;
        }, this));
      }
      return url;
    },

    getImageUrls: function () {
      var urls = [];
      if (this.get('medias')) {
        _.each(this.get('medias'), _.bind(function (media) {
          urls.push(Settings.configValue('webshop.imagesUrl') + '/' + media.url);
        }, this));
      }
      return urls;
    },

    getImages: function () {

      var images = [];

      _.each(this.get('medias'), _.bind(function (media) {
        images.push(media);
      }, this));

      return images;
    },

    addImage: function (file) {
      var defer = $.Deferred();

      var data = new FormData();
      data.append('file', file);

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'webshop/product/'+ this.getId() +'/images/add'),
        data: data,
        processData: false,
        contentType: false,
        success: function (result) {
          var image = null;
          if(result.image)
            image = result.image;
          defer.resolve(image);
        },
        error: function (error) {
          defer.reject(error);
        }
      });
      return defer.promise();
    },

    deleteImage: function (id) {
      var defer = $.Deferred();

      var data = {
        productId: this.getId(),
        id: id
      };

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'webshop/product/images/delete'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    getTag: function () {
      var id = '';
      if (this.get('attributes')) {
        _.each(this.get('attributes'), _.bind(function (attribute) {
          if (attribute.type === 'product-sort')
            id = attribute.id;
        }, this));
      }
      return id;
    },

    setTag: function (id) {
      var defer = $.Deferred();

      if (this.getTag() === id) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productId: this.getId(),
        id: id
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/tag/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    getLayout: function () {
      var id = '';
      if (this.get('config')) {
        _.each(this.get('config'), _.bind(function (value, code) {
          if (code === 'layout')
            id = value;
        }, this));
      }
      return id;
    },

    setLayout: function (id) {
      var defer = $.Deferred();

      if (this.getLayout() === id) {
        defer.resolve();
        return defer.promise();
      }

      this.update('layout/update', id).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    },

    getOutdated: function () {
     return this.get('outdated');
    },

    setOutdated: function (value) {
      var defer = $.Deferred();

      if (this.getOutdated() === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productId: this.getId(),
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/outdated/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    getUnfinished: function () {
      return this.get('unfinished');
    },

    setUnfinished: function (value) {
      var defer = $.Deferred();

      if (this.getUnfinished() === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productId: this.getId(),
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/product/unfinished/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    getUploadLogo: function () {
      var result = false;
      _.each(this.get('properties'), _.bind(function (p) {
        if (p.type === 'logo-upload') {
            if (p.value === 'true')
                result = true;
            else
                result = false;
        }
      }, this));

      return result;
    },

    setUploadLogo: function (value) {
      var defer = $.Deferred();

      if (this.getUploadLogo() === value) {
        defer.resolve();
        return defer.promise();
      }

      if(value === true)
        value = 'true';
      else
        value = 'false';

      this.update('logo-upload/update', value).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    },

    getCompositionEditor: function () {
      var value = false;
      if (this.get('attributes')) {
        _.each(this.get('attributes'), _.bind(function (attribute) {
          if (attribute.type === 'allow-editor')
            value = true;
        }, this));
      }
      return value;
    },

    setCompositionEditor: function (value) {
      var defer = $.Deferred();

      if (this.getCompositionEditor() === value) {
        defer.resolve();
        return defer.promise();
      }

      this.update('composition-editor/update', value).done(_.bind(function () {
        defer.resolve();
      }, this)).fail(_.bind(function (e) {
        defer.reject(e);
      }, this));

      return defer.promise();
    }
  });

  var API = {
    create: function (data) {
      data.outdated = false;
      if (data.attributes) {
        _.each(data.attributes, _.bind(function (attribute) {
          if (attribute.type === 'out-dated')
            data.outdated  = true;
        }, this));
      }
      return data;
    },

    findSupplierNames: function (query) {
      var defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz2', 'webshop/products/findSupplierNames', {value: query}),
          success: function (data) {
          defer.resolve(data);
        },
          error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    getTags: function () {
      var defer = $.Deferred();
      if (this.tags)
        defer.resolve(this.tags);
      else {
        $.ajax({
          type: 'GET',
          url: Settings.url('compuzz2', 'webshop/products/tags'),
          success: _.bind(function (tags) {
            this.tags = new Backbone.Collection(tags);
            defer.resolve(this.tags);
          }, this)
        });
      }
      return defer.promise();
    },

    deleteHistory: function (id, lang, value) {
      var defer = $.Deferred();

      var data = {
        id: id,
        lang: lang,
        value: value
      };

      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz2', 'webshop/product/index/history'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    }
  };

  App.reqres.setHandler('webshop.product.model', function (data) {
    if (!data)
      data = {};
    return app.ns.WebshopProduct.findOrCreate(API.create(data));
  });

  App.reqres.setHandler('webshop.products.search', function (params) {
    return Settings.url('compuzz2', 'webshop/products/search', params);
  });

  App.reqres.setHandler('webshop.products.count', function (params) {
    return Settings.url('compuzz2', 'webshop/products/count', params);
  });

  App.reqres.setHandler('webshop.products.findSupplierNames', function (query) {
    return API.findSupplierNames(query);
  });

  App.reqres.setHandler('webshop.products.tags', function () {
    return API.getTags();
  });

  App.reqres.setHandler('webshop.product.history', function (code, params) {
    return Settings.url('compuzz2', 'webshop/product/' + code + '/index/history', params);
  });

  App.reqres.setHandler('webshop.product.history.count', function (code, params) {
    return Settings.url('compuzz2', 'webshop/product/' + code + '/index/history/count', params);
  });

  App.reqres.setHandler('webshop.product.history.delete', function (id, lang, value) {
    return API.deleteHistory(id, lang, value);
  });

  App.reqres.setHandler('webshop.product.categories', function (id, params) {
    return Settings.url('compuzz2', 'webshop/product/' + id + '/categories', params);
  });

  App.reqres.setHandler('webshop.product.urls', function (id, params) {
    return Settings.url('compuzz2', 'webshop/product/' + id + '/urls', params);
  });

  App.reqres.setHandler('webshop.product.categories.count', function (id, params) {
    return Settings.url('compuzz2', 'webshop/product/' + id + '/categories/count', params);
  });
});

