define('commentsView',[
  'module',
  'backbone',
  'underscore',
  'marionette',
  'app',
  'commentView',
  'commentEditView',
  'settings',
  'entities/common'
], function (
  Module,
  Backbone,
  _,
  Marionette,
  App,
  CommentView,
  EditView,
  Settings
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    childView: CommentView,
    className: 'comments-view',

    childViewOptions: function() {
      return {
        pin: this.options.pin
      };
    },

    childEvents: {
      'delete': 'onDelete',
      'change': 'onChange',
      'pin': 'onPin'
    },

    add: function () {
      EditView.prototype.open({
        model: new Backbone.Model({comment: ''}),
        title: _.i18n('common.new-comment'),
        onConfirm: _.bind(function (value) {
          var currentUserPid = Settings.get('currentUser');
          var comment = new Backbone.Model({
            type: 'order',
            content: value ? value : '',
            externalId: '',
            externalUserId: currentUserPid.externalId ? currentUserPid.externalId : '',
            msgDate: new Date().getTime(),
            title: '',
            user: currentUserPid.secId,
            notSend: true,
            userpid:
            {
              email: currentUserPid.email,
              firstName: currentUserPid.firstName,
              lastName: currentUserPid.lastName,
              logo: currentUserPid.logo
            }
          });

          this.collection.add(comment, { at: 0 });

          if (this.options.add)
            this.options.add(comment);
        }, this)
      });
    },

    onDelete: function(view) {
      this.collection.remove(view.model);
      if (this.options.remove)
        this.options.remove(view.model);
    },

    onPin: function(view) {
      if (this.options.pin)
        this.options.pin(view.model);
    },

    onChange: function(view) {
      if (this.options.change)
        this.options.change(view.model);
    }
  });
});
