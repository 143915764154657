define('ordersDetailOrderClientImagesView',[
  'module',
  'backbone',
  'marionette',
  'settings',
  'underscore',
  'bootbox',
  'galleryView',
  'ordersDetailOrderClientImageView',
  'template!ordersDetailOrderClientImagesView'
], function (
  Module,
  Backbone,
  Marionette,
  Settings,
  _,
  Bootbox,
  GalleryView,
  ImageView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'orders-detail-order-client-images-view',
    childView: ImageView,
    childViewContainer: '.items',

    ui: {
      prev: '.prev',
      next: '.next'
    },

    events: {
      'click @ui.prev': 'onPrev',
      'click @ui.next': 'onNext',
      'click': 'onShowSlider'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    modelEvents: {
      'change:pim': 'updateCollection',
      'change:options': 'updateCollection',
      'change:productBaseCode': 'updateCollection',
      'change:productCode': 'updateCollection',
      'change:purchaseOrderId': 'updateCollection'
    },

    onShow: function () {
      this.listenTo(this.options.product, 'change', _.bind(function () {
        this.updateCollection();
      }, this));

      this.updateCollection();
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {

      if (this.collection.length > 1) {
        this.ui.prev.css('display', 'flex');
        this.ui.next.css('display', 'flex');
      } else {
        this.ui.prev.hide();
        this.ui.next.hide();
      }

      //--------------------  

      for (let childView in this.children._views) {
        if (this.children._views[childView].model.get('index') === this.index)
          this.children._views[childView].show();
        else
          this.children._views[childView].hide();
      }
    },

    updateCollection: function () {
      this.index = 0;
      if (this.model.get('pim') && Settings.configValue('orders.showCompuzzVrpOnPimOrder')) {
        this.model.compuzzVrps().then(_.bind(function (vrps) {
          if (!vrps || vrps.length === 0) {
            Bootbox.dialog({
              title: _.i18n('common.error'),
              message: 'No vrp found on compuzz product.'
            });
          }
          this.urls = this.model.getImageUrls(this.options.product, vrps);
          this.collection.reset();
          _.each(this.urls, function (url, index) {
            var model = new Backbone.Model({
              'url': url,
              'index': index
            });
            this.collection.add(model);
          }, this);
          this.renderContent();
        }, this)).fail(function (err) {
          Bootbox.dialog({
            title: _.i18n('common.error'),
            message: err.responseText
          });
        });
      } else {
        this.urls = this.model.getImageUrls(this.options.product);
        this.collection.reset();
        _.each(this.urls, function (url, index) {
          var model = new Backbone.Model({
            'url': url,
            'index': index
          });
          this.collection.add(model);
        }, this);

        this.renderContent();
      }
    },

    onPrev: function (e) {
      e.stopPropagation();
      this.index--;

      if (this.index < 0)
        this.index = this.collection.length - 1;

      this.renderContent();
    },

    onNext: function (e) {
      e.stopPropagation();
      this.index++;

      if (this.index >= this.collection.length)
        this.index = 0;

      this.renderContent();
    },

    onShowSlider: function () {
      GalleryView.prototype.open(
          'orders.product.gallery.' + this.model.get('purchaseOrderId'),
          _.i18n('orders.product.gallery.title') + ': ' + this.model.get('purchaseOrderId') + ' | ' + this.model.get('productName'),
          new Backbone.Model({urls: this.urls}));
    }
  });
});
