define('webshopProductsDetailBodyImagesView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'buttonView',
  'webshopProductsDetailBodyImagesListView',
  'template!webshopProductsDetailBodyImagesView'
], function (
  Module,
  Backbone,
  Marionette,
  _,
  ButtonView,
  ListView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-images-view',

    regions: {
      addButtonRegion: '.top-banner .add-button-region',
      listRegion: '.list-region'
    },

    onRender: function () {
      this.addButtonView = new ButtonView({
        type: 'add',
        text: _.i18n('common.add-image'),
        title: _.i18n('common.add-image')
      });
      this.addButtonRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));

      //----------------------------

      this.listView = new ListView({
        model: this.model
      });
      this.listRegion.show(this.listView);
    },

    onAdd: function () {
      this.listView.add();
    }
  });
});
