define('ordersController',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'gmailUtils',
  'moment',
  'emailView',
  'modalsAlertView',
  'modalsConfirmationView',
  'ordersAlertView',
  'ordersConfirmationView',
  'ordersHistoryView',
  'ordersLabelsEditView',
  'ordersDuplicateView',
  'ordersDetailInvoicesNewView',
  'ordersDetailInvoicesProformatPreviewView',
  'userSelectorView',
  'entities/customers/customers',
  'entities/orders',
  'entities/mails'
], function (
  App,
  module,
  Backbone,
  _,
  Settings,
  GmailUtils,
  moment,
  EmailView,
  AlertView,
  ConfirmationView,
  OrdersAlertView,
  OrdersConfirmationView,
  HistoryView,
  LabelsEditView,
  DuplicateView,
  NewInvoiceView,
  ProformatPreviewView,
  UserSelectorView
) {
  'use strict';

  module.exports = {
    archive: function (models) {
      if(models.length > 0) {
        var title = _.i18n('orders.archive.confirmation.modal.text');
        if (models.length > 1)
          title = _.i18n('orders.archive.confirmation.modal.text.plural');

        OrdersConfirmationView.prototype.open(
          title,
            models,
          _.bind(function () {
            _.each(models, _.bind(function (model) {
              model.archive().done(_.bind(function () {
                model.fetch();
              }, this)).fail(_.bind(function () {
                OrdersAlertView.prototype.open(_.i18n('orders.archive.alert.modal.text'), '', model);
              }, this));
            }, this));
          }, this)
        );
      }
    },

    unarchive: function (models) {
      if(models.length > 0) {
        var title = _.i18n('orders.unarchive.confirmation.modal.text');
        if (models.length > 1)
          title = _.i18n('orders.unarchive.confirmation.modal.text.plural');

        OrdersConfirmationView.prototype.open(
          title,
          models,
          _.bind(function () {
            _.each(models, _.bind(function (model) {
              model.unarchive().done(_.bind(function () {
                model.fetch();
              }, this)).fail(_.bind(function () {
                OrdersAlertView.prototype.open(_.i18n('orders.unarchive.alert.modal.text'), '', model);
              }, this));
            }, this));
          }, this)
        );
      }
    },

    deleteOrders: function (models, removed) {
      if (models.length > 0) {
        var title = _.i18n('orders.delete.confirmation.modal.text');
        if (models.length > 1)
          title = _.i18n('orders.delete.confirmation.modal.text.plural');

        OrdersConfirmationView.prototype.open(
            title,
            models,
            _.bind(function () {
              _.each(models, _.bind(function (model) {
                model.remove().done(_.bind(function () {
                    if (removed)
                      removed(model);
                }, this)).fail(_.bind(function () {
                  OrdersAlertView.prototype.open(_.i18n('orders.delete.alert.modal.text'), '', model);
                }, this));
              }, this));
            }, this)
        );
      }
    },

    validate: function (models) {
      if(models.length > 0) {
        var title = _.i18n('orders.validate.confirmation.modal.text');
        if (models.length > 1)
          title = _.i18n('orders.validate.confirmation.modal.text.plural');

        OrdersConfirmationView.prototype.open(
            title,
            models,
            _.bind(function () {
              _.each(models, _.bind(function (model) {
                model.validate().done(_.bind(function () {
                  model.fetch();
                }, this)).fail(_.bind(function () {
                  OrdersAlertView.prototype.open(_.i18n('orders.validate.alert.modal.text'), '', model);
                }, this));
              }, this));
            }, this)
        );
      }
    },

    invalidate: function (models) {
      if(models.length > 0) {
        var title = _.i18n('orders.invalidate.confirmation.modal.text');
        if (models.length > 1)
          title = _.i18n('orders.invalidate.confirmation.modal.text.plural');

        OrdersConfirmationView.prototype.open(
            title,
            models,
            _.bind(function () {
              _.each(models, _.bind(function (model) {
                model.invalidate().done(_.bind(function () {
                  model.fetch();
                }, this)).fail(_.bind(function () {
                  OrdersAlertView.prototype.open(_.i18n('orders.invalidate.alert.modal.text'), '', model);
                }, this));
              }, this));
            }, this)
        );
      }
    },

    pimToCompuzz: function (models) {
      if(models.length > 0) {
        var title = _.i18n('orders.pimToCompuzz.confirmation.modal.text');
        if (models.length > 1)
          title = _.i18n('orders.pimToCompuzz.confirmation.modal.text.plural');

        OrdersConfirmationView.prototype.open(
            title,
            models,
            _.bind(function () {
              _.each(models, _.bind(function (model) {
                model.convertPimToCompuzz().done(_.bind(function () {
                  model.fetch();
                }, this)).fail(_.bind(function (e) {
                  var message = '';
                  if(e.responseText)
                    message = e.responseText;
                  OrdersAlertView.prototype.open(_.i18n('orders.pimToCompuzz.alert.modal.text'), message, model);
                }, this));
              }, this));
            }, this)
        );
      }
    },

    createSendInvoiceMail: function (models, endUserId) {
      var defer = $.Deferred();

      this.customer = App.request('customers:entity:end-user-id', endUserId);

      this.customer.fetch().done(_.bind(function () {
        if (this.customer.get('vatNumber') && this.customer.get('vatNumber') !== '') {
          if (this.customer.get('vatNumberChecked') === true) {
            if (this.customer.get('vatNumberValid') === false) {
              ConfirmationView.prototype.open({
                text: _.i18n('orders.vat.not-valid'),
                onConfirm: _.bind(function () {
                  this.CheckPaymentBeforeSendInvoiceMail(models, endUserId).done(_.bind(function () {
                    defer.resolve();
                  }, this));
                }, this)
              });
            } else {
              this.CheckPaymentBeforeSendInvoiceMail(models, endUserId).done(_.bind(function () {
                defer.resolve();
              }, this));
            }
          } else {
            this.customer.checkVat().done(_.bind(function (valid) {
              if (!valid) {
                ConfirmationView.prototype.open({
                  text: _.i18n('orders.vat.not-valid'),
                  onConfirm: _.bind(function () {
                    this.CheckPaymentBeforeSendInvoiceMail(models, endUserId).done(_.bind(function () {
                      defer.resolve();
                    }, this));
                  }, this)
                });
              } else {
                this.CheckPaymentBeforeSendInvoiceMail(models, endUserId).done(_.bind(function () {
                  defer.resolve();
                }, this));
              }
            }, this)).fail(_.bind(function () {
              ConfirmationView.prototype.open({
                text: _.i18n('vat.checkVatError'),
                onConfirm: _.bind(function () {
                  this.CheckPaymentBeforeSendInvoiceMail(models, endUserId).done(_.bind(function () {
                    defer.resolve();
                  }, this));
                }, this)
              });
            }, this));
          }
        } else {
          ConfirmationView.prototype.open({
            text: _.i18n('vat.empty-vat'),
            onConfirm: _.bind(function () {
              this.CheckPaymentBeforeSendInvoiceMail(models, endUserId).done(_.bind(function () {
                defer.resolve();
              }, this));
            }, this)
          });
        }
      }, this));

      return defer.promise();
    },

    CheckPaymentBeforeSendInvoiceMail: function (models, endUserId) {
      var defer = $.Deferred();

      var notPaidOrders = [];
      _.each(models, function (model) {
        if (model.get('payedByCustomer') !== true)
          notPaidOrders.push(model);
      }, this);

      if (notPaidOrders.length > 0) {
        OrdersConfirmationView.prototype.open(
          _.i18n('customer.confirmInvoiceNotPaid'),
          notPaidOrders,
          _.bind(function () {
            this.createInvoice(models, endUserId, false).done(_.bind(function () {
              defer.resolve();
            }, this));
          }, this)
        );
      } else {
        this.createInvoice(models, endUserId, true).done(_.bind(function () {
          defer.resolve();
        }, this));
      }

      return defer.promise();
    },

    createInvoice: function (models, endUserId, paid) {
      var defer = $.Deferred();
      var customer = App.request('customers:entity:end-user-id', endUserId);
      customer.fetch().done(_.bind(function () {

        var pois = [];
        _.each(models, function (model) {
          pois.push(model.get('purchaseOrderId'));
        }, this);

        ProformatPreviewView.prototype.open({
          collection: new Backbone.Collection(models),
          onConfirm: _.bind(function () {
            if (Settings.configValue('orders.newInvoicePaymentTerms') === true) {
              NewInvoiceView.prototype.open({
                pois: pois,
                customer: customer,
                paid: paid,
                onConfirm: _.bind(function (invoice) {
                  this.sendInvoiceMail(this.customer, invoice, paid);
                  defer.resolve();
                }, this),
                onFail: _.bind(function () {
                  AlertView.prototype.open('', 'VERIFY CUSTOMER DATA');
                  defer.fail();
                }, this)
              });
            } else {
              App.request('order:generate-invoice', pois, null).done(_.bind(function (invoice) {
                defer.resolve();
                this.sendInvoiceMail(this.customer, invoice, paid);
              }, this)).fail(_.bind(function () {
                defer.fail();
              }, this));
            }
          }, this),
          onCancel: _.bind(function () {
              defer.fail();
          }, this)
        });
      }, this));
      return defer.promise();
    },

    sendInvoiceMail: function(customer, invoice, paid, callback) {
      var locale = customer.get('locales') || Settings.get('lang').toLowerCase();

      var attachments = [];
      attachments.push({
        url: Settings.url('dl', 'proforma', {invoiceSecId: invoice.secId, lang: locale.toLowerCase()}),
        name: invoice.formatedInvoiceNumber + '.pdf',
        'content-type': 'application/pdf'
      });


      var contactEmail = customer.get('email') || customer.get('endUserId');
      var invoiceAddress = customer.get('invoiceEmail');
      var to = contactEmail;
      var cc = '';
      var bcc = '';
      if(Settings.configValue('mails.invoice.bcc'))
        bcc = Settings.configValue('mails.invoice.bcc');

      if(contactEmail && invoiceAddress) {
        to = customer.get('invoiceEmail');
        cc = contactEmail;
      }

      var from = App.request('mails:getFromMail', Settings.configValue('mails.invoice.from'), locale);
      App.request('mails:checkFrom', from).done(_.bind(function () {
        EmailView.prototype.open({
          from: from,
          to: to,
          cc: cc,
          bcc: bcc,
          allowFromSelection: Settings.configValue('mails.invoice.allowFromSelection'),
          templateTypes: ['MAIL_SIGN', 'MAIL_INVOICE', 'MAIL_INVOICE_NOT_PAID'],
          defaultTemplate: (paid ? 'MAIL_INVOICE' : 'MAIL_INVOICE_NOT_PAID'),
           templateData: {
            invoice: invoice
          },
          templateLocale: locale,
          id: 'invoice.' + invoice.secId,
          title: _.i18n('common.invoiceEmail') + ' | ' + invoice.formatedInvoiceNumber,
          attachments: attachments,
          onMailSend: _.bind(function (mails) {
            App.request('order:invoice-sent', invoice.secId, mails).done(_.bind(function () {
              if(callback)
                callback();
            }, this));
          }, this)
        });
      }, this)).fail(_.bind(function () {
        AlertView.prototype.open('', _.i18n('common.googleFromEmailError').replace('%s', from));
      }, this));
    },

    copyToClipboard: function (value) {
      var $temp = $('<textarea>');
      var brRegex = /<br\s*[\/]?>/gi;
      $('body').append($temp);
      $temp.val(value.replace(brRegex, '\r\n').trim()).select();
      document.execCommand('copy');
      $temp.remove();
    },

    contextMenu: function(type, orders, lastSelected, callback) {
      var menu = {};      

      //------------------
      if(orders.length === 1) {
        var order = orders[0];
        menu.poi = {
          name: order.get('purchaseOrderId'),
          callback: _.bind(function () {
            this.copyToClipboard(order.get('purchaseOrderId'));
            callback();
          }, this)
        };
        menu.poiSep = '---------';
      }

      //------------------
      if(orders.length === 1) {
        menu.copyMail = {
          name: lastSelected.get('endUserId'),
          callback: _.bind(function () {
            this.copyToClipboard(lastSelected.get('endUserId'));
            callback();
          }, this)
        };
        menu.copyMailSep = '---------';
      }

      //------------------
      menu.filterCustomer = {
        name: _.i18n('common.filterby').replace('%s', lastSelected.get('endUserId')),
        callback: _.bind(function () {
          if(lastSelected.get('endUserId'))
            this.filtersView.onConfirm(new Backbone.Model({
              endUserId: lastSelected.get('endUserId')
            }));
          callback();
        }, this)
      };
      menu.filterCustomerSep = '---------';

      //------------------
      menu.mail = {
        name: _.i18n('common.email'),
        callback: _.bind(function () {
          var customer = App.request('customers:entity:end-user-id', lastSelected.get('endUserId'));
          customer.fetch().done(_.bind(function () {
            var pois = [];
            var ordersJson = [];
            var pfUrl = '';
            var proofsheets = [];
            var locale = 'FR';
            var attachments = [];
            var trackings = [];

            if (Settings.configValue('customerDefaultMailLanguage'))
              locale = Settings.configValue('customerDefaultMailLanguage');
            if (customer.get('locales'))
              locale = customer.get('locales');

            _.each(orders, function (order) {
              pois.push(order.get('purchaseOrderId'));
              ordersJson.push(order.toJSON());

              //--------
              var proofsheetUrl = Settings.getWebshopProofSheetUrl(
                  locale,
                  customer.get('countryCode'),
                  order.get('purchaseOrderId'),
                  true,
                  false);

              var urlTextContent = order.get('productName');
              if(Settings.configValue('orders.offerProoofsheetLinkText') === 'poi')
                urlTextContent = order.get('purchaseOrderId');

              var urlText = _.i18n('mails.proofsheet').replace('%s', '"' + urlTextContent + '"');
              pfUrl += '<a href="'+ proofsheetUrl + '">' + urlText + '</a><br>';

              var productName = _.find(order.get('productNameDt').values, function(obj, key) {
                return key.toLowerCase() === locale.toLowerCase();
              });

              if(!productName)
                productName = order.get('productName');

              proofsheets.push({
                url: proofsheetUrl,
                poi: order.get('purchaseOrderId'),
                productName: productName
              });

              //--------
              /*var url = 'https://x3wn3zu7te.execute-api.eu-west-1.amazonaws.com/prod/dl/proforma?pois=530262594867191';
              url += '&template=PDF_DELIVERY_NOTE&fileName=Bon%20de%20livraison%20-%2020230704%20-';
              url += '%20Alexandre%20Skupiewski%20-%20530262594867191&lang=fr';
              url += '&tokenId=xqwuRAcwuLboaHyJUCtMQ4ktNdOoFL87lxv2EKchrmpSWpizqvqykivZB7BIYaQj761DktXQTgT96BkDXUYlk27JuwvGXG82';
              url += 'kSPoJ966yI1Bqa3YYe8RUgyZhcKBIbEvn5pUTxtPiWEZpGI7G8zjxefo';

              attachments.push({
                url: url,
                name: order.get('purchaseOrderId') + '.pdf',
                'content-type': 'application/pdf'
              });
              attachments.push({
                url: url,
                name: order.get('purchaseOrderId') + '.pdf',
                'content-type': 'application/pdf'
              });
              attachments.push({
                url: url,
                name: order.get('purchaseOrderId') + '.pdf',
                'content-type': 'application/pdf'
              });
              attachments.push({
                url: url,
                name: order.get('purchaseOrderId') + '.pdf',
                'content-type': 'application/pdf'
              });*/

              var pdfUrl = Settings.getWebshopProofSheetUrl(
                  locale,
                  customer.get('countryCode'),
                  order.get('purchaseOrderId'),
                  true,
                  true);

              var url = Settings.url('x2Ws', 'pdf', {
                param: btoa(pdfUrl)
              });

              attachments.push({
                url: url,
                name: order.getProofsheetFilename(customer) + '.pdf',
                'content-type': 'application/pdf'
              });
            }, this);

            orders[0].getModelData(orders).done(function(listOrders) {
              _.each(listOrders, _.bind(function (order) {
                if(order.trackings) {
                  _.each(order.trackings, function (tracking) {
                    var model = App.request('tracking-tab:new-model', tracking);
                    if(model.get('destinationType') === 'tocustomer')
                      model.set('trackingUrl', model.trackingUrl(customer));
                      trackings.push(model.toJSON());
                  }, this);
                }
              }, this));

              var from = App.request('mails:getFromMail', Settings.configValue('mails.standard.from'), locale);

              App.request('mails:checkFrom', from).done(_.bind(function () {
                EmailView.prototype.open({
                  from: from,
                  to: customer.get('email') || customer.get('endUserId'),
                  allowFromSelection: Settings.configValue('mails.standard.allowFromSelection'),
                  templateTypes: ['MAIL_SIGN', 'MAIL_OFFER', 'MAIL_OFFER_MODIFIED', 'MAIL_ORDER_TRACKING'],
                  defaultTemplate: 'MAIL_SIGN',
                  templateData: {
                    pois: pois,
                    pfUrl: pfUrl,
                    proofsheets: proofsheets,
                    orders: ordersJson,
                    user: Settings.get('currentUser'),
                    customer: customer.toJSON(),
                    trackings: trackings
                  },
                  templateLocale: locale,
                  id: pois.join(','),
                  title: pois.join(', '),
                  attachments: attachments
                });
              }, this)).fail(_.bind(function () {
                if (!from)
                  AlertView.prototype.open('', _.i18n('common.googleFromEmailEmpty'));
                else
                  AlertView.prototype.open('', _.i18n('common.googleFromEmailError').replace('%s', from));
              }, this));
            });
          }, this));

          callback();
        }, this)
      };
      menu.mailSep = '---------';

      //------------------

      var showCancel = false;
      _.each(orders, function (order) {
        if (order.get('status') !== 'CANCELLED')
          showCancel = true;
      });

      if(showCancel) {
        menu.cancel = {
          name: _.i18n('common.cancel'),
          callback: _.bind(function () {
            _.each(orders, function (order) {
              if (order.get('status') !== 'CANCELLED') {
                order.updateStatus('CANCELLED').done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);

            callback();
          }, this)
        };
        menu.cancelSep = '---------';
      }

      //------------------
      var showDecline = false;
      _.each(orders, function (order) {
        if (order.get('status') !== 'DECLINED')
          showDecline = true;
      });

      if(showDecline) {
        menu.decline = {
          name: _.i18n('common.declined'),
          callback: _.bind(function() {
            _.each(orders, function (order) {
              if(order.get('status') !== 'DECLINED') {
                order.updateStatus('DECLINED').done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);
            callback();
          }, this)
        };
        menu.declineSep = '---------';
      }

      //------------------
      if(orders.length === 1) {
        menu.duplicacte = {
          name: _.i18n('common.duplicate'),
          callback: _.bind(function() {
            var order = orders[0];
            DuplicateView.prototype.open(
              order
            );
            callback();
          }, this)
        };
        menu.duplicateSep = '---------';
      }

      //------------------
      menu.history = {
        name: _.i18n('common.history'),
        callback: _.bind(function() {
          _.each(orders, function (order) {
            HistoryView.prototype.open(order);
          }, this);
          callback();
        }, this)
      };
      menu.historySep = '---------';

      //------------------
      var showPayedByCustomer = false;
      _.each(orders, function (order) {
        if (order.get('payedByCustomer') !== true)
          showPayedByCustomer = true;
      });

      if(showPayedByCustomer) {
        menu.payedByCustomer = {
          name: _.i18n('offerList.payedByCustomer'),
          callback: _.bind(function() {
            _.each(orders, function (order) {
              if(order.get('payedByCustomer') !== true) {
                order.updateCustomerPayment(true).done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);
            callback();
          }, this)
        };
      }

      //------------------
      var showUnPayedByCustomer = false;
      _.each(orders, function (order) {
        if (order.get('payedByCustomer') !== false)
          showUnPayedByCustomer = true;
      });

      var ordersToUnpay = [];
        _.each(orders, function (order) {
          if (order.get('payedByCustomer') !== false)
            ordersToUnpay.push(order);
        }, this);

      if(showUnPayedByCustomer) {
        menu.unPayedByCustomer = {
          name: _.i18n('offerList.UnPayedByCustomer'),
          callback: _.bind(function() {
            OrdersConfirmationView.prototype.open(
              _.i18n('orders.unpay.confirmation.modal.text'),
              ordersToUnpay,
              _.bind(function () {
                _.each(ordersToUnpay, function (order) {
                  order.updateCustomerPayment(false).done(_.bind(function () {
                    order.fetch();
                  }, this));
                }, this);
              }, this)
            );

            callback();
          }, this)
        };
      }
      if(showPayedByCustomer || showUnPayedByCustomer)
        menu.payedByCustomerSep = '---------';

      //------------------
      menu.proFormat = {
        name: _.i18n('offerList.proforma'),
        callback: _.bind(function() {
          var pois = [];
          _.each(orders, function (order) {
            pois.push(order.get('purchaseOrderId'));
          }, this);
          if(lastSelected) {
            this.customer = App.request('customers:entity:end-user-id', lastSelected.get('endUserId'));

            this.customer.fetch().done(_.bind(function () {
              if(this.customer.get('vatNumber') && this.customer.get('vatNumber') !== '') {
                if (this.customer.get('vatNumberChecked') === true) {
                  if (this.customer.get('vatNumberValid') === false) {
                    ConfirmationView.prototype.open({
                      text: _.i18n('orders.vat.not-valid'),
                      onConfirm: _.bind(function () {
                        window.location = Settings.url('dl', 'proforma', {
                          pois: pois.join(',')
                        });
                      }, this)
                    });
                  } else {
                    window.location = Settings.url('dl', 'proforma', {
                      pois: pois.join(',')
                    });
                  }
                } else {
                  this.customer.checkVat().done(_.bind(function (valid) {
                    if (!valid) {
                      ConfirmationView.prototype.open({
                        text: _.i18n('vat.not-valid'),
                        onConfirm: _.bind(function () {
                          window.location = Settings.url('dl', 'proforma', {
                            pois: pois.join(',')
                          });
                        }, this)
                      });
                    } else {
                      window.location = Settings.url('dl', 'proforma', {
                        pois: pois.join(',')
                      });
                    }
                  }, this)).fail(_.bind(function () {
                    ConfirmationView.prototype.open({
                      text: _.i18n('vat.checkVatError'),
                      onConfirm: _.bind(function () {
                        window.location = Settings.url('dl', 'proforma', {
                          pois: pois.join(',')
                        });
                      }, this)
                    });
                  }, this));
                }
              } else {
                ConfirmationView.prototype.open({
                  text: _.i18n('vat.empty-vat'),
                  onConfirm: _.bind(function () {
                    window.location = Settings.url('dl', 'proforma', {
                      pois: pois.join(',')
                    });
                  }, this)
                });
              }
            }, this));
          }

          callback();
        }, this)
      };
      menu.proFormatSep = '---------';

      //------------------
      var showFlag = false;
      _.each(orders, function (order) {
        if (order.get('flag') !== 'E')
          showFlag = true;
      });

      if(showFlag) {
        menu.flag = {
          name: _.i18n('offerList.flag'),
          callback: _.bind(function() {
            _.each(orders, function (order) {
              if(order.get('flag') !== 'E') {
                order.updateFlag('E').done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);
            callback();
          }, this)
        };
        menu.flagSep = '---------';
      }

      //------------------
      var showUnFlag = false;
      _.each(orders, function (order) {
        if (order.get('flag') === 'E')
          showUnFlag = true;
      });

      if(showUnFlag) {
        menu.unflag = {
          name: _.i18n('offerList.unflag'),
          callback: _.bind(function() {
            _.each(orders, function (order) {
              if(order.get('flag') === 'E') {
                order.updateFlag('').done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);
            callback();
          }, this)
        };
        menu.unflagSep = '---------';
      }

      //------------------
      var showPrio0 = false;
      _.each(orders, function (order) {
        if (order.get('priority') !== 0)
          showPrio0 = true;
      });

      if(showPrio0) {
        menu.prio0 = {
          name: _.i18n('offerList.prio0'),
          callback: _.bind(function () {
            _.each(orders, function (order) {
              if (order.get('priority') !== 0) {
                order.updatePriority(0).done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);
            callback();
          }, this)
        };
      }

      //------------------
      var showPrio1 = false;
      _.each(orders, function (order) {
        if (order.get('priority') !== 1)
          showPrio1 = true;
      });

      if(showPrio1) {
        menu.prio1 = {
          name: _.i18n('offerList.prio1'),
          callback: _.bind(function () {
            _.each(orders, function (order) {
              if (order.get('priority') !== 1) {
                order.updatePriority(1).done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);
            callback();
          }, this)
        };
      }

      //------------------
      var showPrio2 = false;
      _.each(orders, function (order) {
        if (order.get('priority') !== 2)
          showPrio2 = true;
      });

      if(showPrio2) {
        menu.prio2 = {
          name: _.i18n('offerList.prio2'),
          callback: _.bind(function () {
            _.each(orders, function (order) {
              if (order.get('priority') !== 2) {
                order.updatePriority(2).done(_.bind(function () {
                  order.fetch();
                }, this));
              }
            }, this);
            callback();
          }, this)
        };
      }
      menu.prioSep = '---------';

      //------------------
      menu.toInvoice = {
        name: _.i18n('offerList.toInvoice'),
        callback: _.bind(function() {
          if(lastSelected.get('endUserId'))
            this.createSendInvoiceMail(orders, lastSelected.get('endUserId')).done(_.bind(function () {
              _.each(orders, function (order) {
                order.trigger('invoices:new');
              }, this);
            }, this));
          callback();
        }, this)
      };
      menu.toInvoiceSep = '---------';

      //------------------
      menu.convert = {
        name: type === 'command' ? _.i18n('offerList.convertToOffer') : _.i18n('offerList.convertToOrder'),
        callback: _.bind(function() {
          _.each(orders, function (order) {
            var newType = type === 'command' ? 'offer' : 'command';
            order.updateType(newType).done(_.bind(function () {
              this.bodyView.collection.remove(order);
            }, this));
          }, this);
          callback();
        }, this)
      };
      menu.convertSep = '---------';

      //------------------
      menu.deliveryNote = {
        name: _.i18n('common.downloadDeliveryNote'),
        callback: _.bind(function() {
          var pois = [];
          _.each(orders, function (order) {
            pois.push(order.get('purchaseOrderId'));
          }, this);

          if(pois.length > 0) {
            var order = orders[0];

            var customer = App.request('customers:entity:end-user-id', order.get('endUserId'));
            customer.fetch().done(_.bind(function () {
              window.location = order.getDeliveryFileUrl(pois, customer);
            }, this));
          }
          callback();
        }, this)
      };
      menu.deliveryNoteSep = '---------';

      //------------------
      var statusesItems = {};
      App.request('orders:statuses', type).done(_.bind(function (statuses) {
        _.each(statuses.models, _.bind(function (status) {
          statusesItems[status.get('statusConst')] = {
            name: status.get('status'),
            callback: _.bind(function () {
              _.each(orders, function (order) {
                order.updateStatus(status.get('statusConst')).done(_.bind(function () {
                  order.fetch();
                }, this));
              }, this);

              //--------------------
              try {
                if(status.get('params')) {
                  var params = JSON.parse(status.get('params'));
                  this.sendMail(orders, params);
                }
              } catch (e) {
                console.log(e);
              }

              callback();
            }, this)
          };
        }, this));
      }, this));

      menu.statuses = {
        name: _.i18n('orderList.status'),
        items: statusesItems
      };
      menu.statusesSep = '---------';

      //------------------
      menu.colors = {
        name: _.i18n('orderList.color'),
        callback: _.bind(function () {
          if (orders.length > 0)
            LabelsEditView.prototype.open(orders);
          callback();
        }, this)
      };

      menu.colorsSep = '---------';

      //------------------
      if (Settings.configValue('showProofsheetLink')) {
        menu.proofsheet = {
          name: _.i18n('common.proofsheet'),
          callback: _.bind(function() {
            _.each(orders, function (order) {
              var customer = App.request('customers:entity:end-user-id', order.get('.endUserId'));
              customer.fetch().done(_.bind(function() {
                var locale = 'fr';
                var countryCode = '';

                if (customer.get('locales') && (customer.get('locales')).length >= 2)
                  locale = (customer.get('locales')).substring(0, 2);

                if (customer.get('countryCode') && (customer.get('countryCode')).length >= 2)
                  countryCode = (customer.get('countryCode')).substring(0, 2);

                var shopUrl = Settings.getWebshopUrl(locale, countryCode);
                var proofsheetUrl = Settings.configValue('orderDetail.webShopProofSheetUrl');

                if (shopUrl && proofsheetUrl) {
                  var url = shopUrl + proofsheetUrl.replace('{poi}', order.get('purchaseOrderId'));
                  window.open(url, '_blank');
                }
              }, this));
            }, this);
            callback();
          }, this)
        };
        menu.proofsheetSep = '---------';
      }

      //------------------
      menu.downloadProofsheet = {
        name: _.i18n('common.downloadProofsheet'),
        callback: _.bind(function() {
          _.each(orders, function (order) {
            var customer = App.request('customers:entity:end-user-id', order.get('endUserId'));
            customer.fetch().done(_.bind(function () {
              var fileName = order.getProofsheetFilename(customer);
              window.open(order.getProofsheetDownloadUrl(fileName, customer), '_blank');
            }, this));
          });
          callback();
        }, this)
      };
      menu.downloadProofsheetSep = '---------';

      //------------------
      var showArchive = false;
      _.each(orders, function (order) {
        if (order.get('archived') !== true)
          showArchive = true;
      });

      if(showArchive) {
        menu.archive = {
          name: _.i18n('common.archive'),
          callback: _.bind(function () {
            var models = [];
            _.each(orders, function (order) {
              if (order.get('archived') !== true)
                models.push(order);
            }, this);
            this.archive(models);
            callback();
          }, this)
        };
      }

      //------------------
      var showUnArchive = false;
      _.each(orders, function (order) {
        if (order.get('archived') !== false)
          showUnArchive = true;
      });

      if(showUnArchive) {
        menu.unarchive = {
          name: _.i18n('common.unarchive'),
          callback: _.bind(function () {
            var models = [];
            _.each(orders, function (order) {
              if (order.get('archived') !== false)
                models.push(order);
            }, this);
            this.unarchive(models);
            callback();
          }, this)
        };
      }
      menu.archiveSep = '---------';

      //------------------
      menu.deleteOrder = {
        name: _.i18n('common.delete'),
        callback: _.bind(function() {
          this.deleteOrders(orders, _.bind(function (order) {
            this.bodyView.collection.remove(order);
          }, this));
          callback();
        }, this)
      };
      menu.deleteSep = '---------';

      //------------------
      menu.assignUser = {
        name: _.i18n('common.assignTo'),
        callback: _.bind(function() {

          var userids = [];
          _.each(orders, function (order) {
            if(order.get('userpids')) {
              var ids = [];
              _.each(order.get('userpids'), function (userpid) {
                ids.push(userpid.secId);
              });

              userids = _.union(userids, ids);
            }
          }, this);

          _.each(orders, function (order) {
            if(order.get('userpids')) {
              var ids = [];
              _.each(order.get('userpids'), function (userpid) {
                ids.push(userpid.secId);
              });

              userids = _.intersection(userids, ids);
            }
          }, this);

          App.request('kanban-tickets:get-users').done(_.bind(function (users) {
            var userpids = [];
            _.each(users, _.bind(function (user) {
              if(_.contains(userids, user.secId))
                userpids.push(user);
            }, this));

            var usersCollection = new Backbone.Collection(userpids);

            UserSelectorView.prototype.open({
              users: usersCollection.models,
              multiselect: true,
              onSelected: _.bind(function (user) {
                var deferred = new $.Deferred();

                _.each(orders, function (order) {
                  order.assignUser(user.get('secId')).done(_.bind(function () {
                      order.fetch();
                  }, this));
                }, this);

                deferred.resolve();
                return deferred;
              }, this),
              onUnSelected: _.bind(function (user) {
                var deferred = new $.Deferred();

                _.each(orders, function (order) {
                  order.unassignUser(user.get('secId')).done(_.bind(function () {
                    order.fetch();
                  }, this));
                }, this);

                deferred.resolve();
                return deferred;
              }, this)
            });
            callback();
          }, this));

          callback();
        }, this)
      };
      menu.assignUserSep = '---------';

      //------------------
      if(Settings.configValue('orders.showValidationButton')) {
        var showValidate = false;
        _.each(orders, function (order) {
          if (order.get('validated') !== true)
            showValidate = true;
        });

        if(showValidate) {
          menu.validate = {
            name: _.i18n('common.validate'),
            callback: _.bind(function () {
              var models = [];
              _.each(orders, function (order) {
                if (order.get('validated') !== true)
                  models.push(order);
              }, this);
              this.validate(models);
              callback();
            }, this)
          };
        }

        //------------------
        var showUnValidate = false;
        _.each(orders, function (order) {
          if (order.get('validated') !== false)
            showUnValidate = true;
        });

        if(showUnValidate) {
          menu.unValidate = {
            name: _.i18n('common.unvalidate'),
            callback: _.bind(function () {
              var models = [];
              _.each(orders, function (order) {
                if (order.get('validated') === true)
                  models.push(order);
              }, this);
              this.invalidate(models);
              callback();
            }, this)
          };
        }
      }

      return menu;
    },

    sendMail: function (orders, params) {
      try {
        if(!params || !params.type || !params.params || !params.params.type)
          return;
        if (params.type !== 'sendMail')
          return;

        var type = params.params.type;
        if (!type)
          return;

        //-------------------------
        var groups = [];

        _.each(orders, _.bind(function (order) {
          var group = null;
          _.each(groups, _.bind(function (g) {
            var newGroup = false;
            if (g.userId !== order.get('endUserId'))
              newGroup = true;

            if (type === 'MAIL_ORDER_SUPPLIER') {
              if (!g.refDeliveryAddress && order.get('refDeliveryAddress') ||
                  g.refDeliveryAddress && !order.get('refDeliveryAddress') ||
                  g.refDeliveryAddress.addressL1 !== order.get('refDeliveryAddress').addressL1 ||
                  g.refDeliveryAddress.addressL2 !== order.get('refDeliveryAddress').addressL2 ||
                  g.refDeliveryAddress.city !== order.get('refDeliveryAddress').city ||
                  g.refDeliveryAddress.countryCode !== order.get('refDeliveryAddress').countryCode ||
                  g.refDeliveryAddress.firstName !== order.get('refDeliveryAddress').firstName ||
                  g.refDeliveryAddress.houseNumber !== order.get('refDeliveryAddress').houseNumber ||
                  g.refDeliveryAddress.lastName !== order.get('refDeliveryAddress').lastName ||
                  g.refDeliveryAddress.name !== order.get('refDeliveryAddress').name ||
                  g.refDeliveryAddress.zip !== order.get('refDeliveryAddress').zip ||
                  g.supplier.secId !== order.get('supplier').secId) {
                newGroup = true;
              }
            }

            if (!newGroup)
              group = g;
          }, this));

          if (group === null) {
            group = {
              userId: order.get('endUserId'),
              refDeliveryAddress: order.get('refDeliveryAddress'),
              orders: new Backbone.Collection(),
              supplier: order.get('supplier')
            };
            groups.push(group);
          }

          group.orders.push(order);
        }, this));

        //-------------------------
        var excludeAttachment = false;
        if(params.params && params.params.excludeAttachment)
          excludeAttachment = params.params.excludeAttachment;

        _.each(groups, _.bind(function (group) {
          var customer = App.request('customers:entity:end-user-id', group.userId);
          customer.fetch().done(_.bind(function () {
            var locale = 'FR';
            var pois = [];

            _.each(group.orders.models, function (order) {
                pois.push(order.get('purchaseOrderId'));
            }, this);

            if (Settings.configValue('customerDefaultMailLanguage'))
              locale = Settings.configValue('customerDefaultMailLanguage');

            var attachments = [];

            if (type === 'MAIL_ORDER_SUPPLIER') {
              _.each(group.orders.models, _.bind(function (order) {

                if (order.get('proofsheetFileUid')) {
                  attachments.push({
                    url: Settings.url('file', order.get('proofsheetFileUid')),
                    name: 'Printproof',
                    'content-type': 'application/pdf'
                  });
                }

                attachments.push({
                  url: order.getDeliveryFileUrl(pois, customer),
                  name: order.getDeliveryFileName(pois, customer),
                  'content-type': 'application/pdf'
                });

                _.each(order.get('attachments'), function (attachment) {
                  if (attachment.flag) {
                    var contentType = '*';
                    if(attachment.fileName.indexOf('.pdf') !== -1)
                      contentType = 'application/pdf';
                    if(attachment.fileName.indexOf('.png') !== -1)
                      contentType = 'image/png';
                    if(attachment.fileName.indexOf('.jpg') !== -1)
                      contentType = 'image/jpg';
                    if(attachment.fileName.indexOf('.jpeg') !== -1)
                      contentType = 'image/jpeg';
                    if(attachment.fileName.indexOf('.gif') !== -1)
                      contentType = 'image/gif';
                    if(attachment.fileName.indexOf('.bmp') !== -1)
                      contentType = 'image/bmp';
                    if(attachment.fileName.indexOf('.tiff') !== -1)
                      contentType = 'image/tiff';
                    if(attachment.fileName.indexOf('.tif') !== -1)
                      contentType = 'image/tiff';
                    if(attachment.fileName.indexOf('.doc') !== -1)
                      contentType = 'application/msword';

                    attachments.push({
                      url: Settings.url('file', attachment.fileUid),
                      name: attachment.fileName,
                      'content-type': contentType
                    });
                  }
                });
              }, this));

              App.request('caccounts:get-share-from-sorted').done(_.bind(function (suppliers) {
                var supplier = _.find(suppliers, function (supplier) {
                  return supplier.caccountFrom.secId === group.supplier.secId;
                });

                if(supplier.caccountFrom && supplier.caccountFrom.language)
                  locale = supplier.caccountFrom.language;

                if (supplier && supplier.orderEmail) {
                  var from = App.request('mails:getFromMail', params.params.from, locale);

                  App.request('mails:checkFrom', from, params.params.from).done(_.bind(function () {
                    EmailView.prototype.open({
                      from: from,
                      to: supplier.orderEmail,
                      allowFromSelection: Settings.configValue('mails.supplier.allowFromSelection'),
                      templateTypes: ['MAIL_SIGN', 'MAIL_ORDER_SUPPLIER'],
                      defaultTemplate: 'MAIL_ORDER_SUPPLIER',
                      templateData: {
                        user: Settings.get('currentUser'),
                        orders: group.orders.toJSON(),
                        pois: pois,
                        supplier: supplier,
                        deliveryAddress: group.refDeliveryAddress,
                        customer: customer.toJSON(),
                        locale: locale
                      },
                      templateLocale: locale,
                      attachments: attachments,
                      title: pois.join(', '),
                      id: pois.join(',')
                    });
                  }, this)).fail(_.bind(function () {
                    AlertView.prototype.open('', _.i18n('common.googleFromEmailError').replace('%s', from));
                  }, this));
                }
              }, this));

            } else {
              if (customer.get('locales'))
                locale = customer.get('locales');

              if(excludeAttachment !== true) {
                var proofsheets = [];

                _.each(group.orders.models, _.bind(function (order) {

                  var proofsheetUrl = Settings.getWebshopProofSheetUrl(
                      locale,
                      customer.get('countryCode'),
                      order.get('purchaseOrderId'),
                      true,
                      false);

                  var productName = null;

                  if(order.get('productNameDt')) {
                    productName = _.find(order.get('productNameDt').values, function(obj, key) {
                      return key.toLowerCase() === locale.toLowerCase();
                    });
                  }

                  if(!productName)
                    productName = order.get('productName');

                  proofsheets.push({
                    url: proofsheetUrl,
                    poi: order.get('purchaseOrderId'),
                    productName: productName
                  });

                  if (Settings.configValue('board.emails.proofsheet.pdfAttachment')) {
                    var pdfProofsheetUrl = Settings.getWebshopProofSheetUrl(
                        locale,
                        customer.get('countryCode'),
                        order.get('purchaseOrderId'),
                        true,
                        true);
                    var printUrl = Settings.url('x2Ws', 'pdf', {
                      param: btoa(pdfProofsheetUrl)
                    });
                    attachments.push({
                      url: printUrl,
                      name: order.getProofsheetFilename(customer) + '.pdf',
                      'content-type': 'application/pdf'
                    });
                  }
                }, this));
              }

              var trackings = [];

              _.each(group.orders.models, _.bind(function (order) {
                if(order.get('trackings')) {
                  _.each(order.get('trackings'), function (tracking) {
                    var model = App.request('tracking-tab:new-model', tracking);
                    if(model.get('destinationType') === 'tocustomer')
                      model.set('trackingUrl', model.trackingUrl(customer));
                    trackings.push(model.toJSON());
                  }, this);
                }
              }, this));

              var from = App.request('mails:getFromMail', params.params.from, locale);
              App.request('mails:checkFrom', from, params.params.from).done(_.bind(function () {
                var templates = ['MAIL_SIGN', 'MAIL_OFFER', 'MAIL_OFFER_MODIFIED', 'MAIL_ORDER_TRACKING'];

                if (!_.contains(templates, type))
                  templates.push(type);

                EmailView.prototype.open({
                  from: from,
                  to: customer.get('email') || customer.get('endUserId'),
                  allowFromSelection: params.params.allowFromSelection,
                  templateTypes: templates,
                  defaultTemplate: type,
                  templateData: {
                    proofsheets: proofsheets,
                    orders: group.orders.toJSON(),
                    pois: pois,
                    user: Settings.get('currentUser'),
                    customer: customer.toJSON(),
                    trackings: trackings
                  },
                  templateLocale: locale,
                  attachments: attachments,
                  title: pois.join(', '),
                  id: pois.join(','),
                  onMailSend: _.bind(function (mails) {
                    if (type === 'MAIL_TRACKING' || type === 'MAIL_ORDER_TRACKING') {
                      _.each(group.orders.models, function (order) {
                        _.each(order.get('trackings'), function (tracking) {
                          var model = App.request('tracking-tab:new-model', tracking);
                          model.set('sentDate', new Date(), {silent: true});
                          model.set('sentEmail', mails, {silent: true});
                          model.unset('refCommandLine', {silent: true});
                          model.save(order.get('purchaseOrderId'));
                        }, this);
                      }, this);
                    }
                  }, this)
                });
              }, this)).fail(_.bind(function () {
                AlertView.prototype.open('', _.i18n('common.googleFromEmailError').replace('%s', from));
              }, this));
            }
          }, this));

        }, this));

      } catch (err) {
        console.log(err);
      }
    },

    createAddress: function (data, cr) {
      var addressFormat = Settings.configValue('orders.addressFormat');

      data.country = '';
      if(data.countryCode) {
        var country = _.findWhere(Settings.get('countries'), {code: data.countryCode});
        if(country)
          data.country = country.name;
      }

      var address = '';
      _.each(addressFormat, function (item) {
        var line = item.format;
        _.each(item.fields, function (field) {
          if(data[field])
            line = line.replace('%' + field, data[field]);
          else
            line = line.replace('%' + field, '');
        }, this);
        if(line.trim() !== '') {
          if(address !== '')
            address += '%cr';
          address += line.trim();
        }
      }, this);

      address = address.replaceAll('%cr', cr).trim();

      return address;
    }
  };
});

