
define('template!ordersDetailOrderClientView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n  <div class="title">' +
((__t = ( _.i18n('orders.detail.client-title') )) == null ? '' : __t) +
'</div>\n  <div class="edit" title="' +
((__t = ( _.i18n('orders.editClientButtonTitle') )) == null ? '' : __t) +
'">\n    <svg class="btn-edit-client"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>\n  </div>\n</div>\n<div class="body">\n  <div class="top">\n    <div class="column">\n      <div class="create-date item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.date.create') )) == null ? '' : __t) +
'</div>\n        </div>          \n        <div class="value"></div>\n      </div>  \n      <div class="company-name item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-company-name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>\n      <div class="contact-person item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.contactPerson') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-contact-person" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>\n      <div class="mail item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-mail" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>\n      <div class="invoice-email item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.invoiceEmail') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-invoice-mail" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>\n      <div class="client-code item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-client-code" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>\n      <div class="phone item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div> \n      <div class="mobile-phone item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.mobilePhone') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-mobile-phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>         \n    </div>\n    <div class="column">         \n      <div class="vat-region"></div>     \n      <div class="kvk item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.kvk') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-kvk" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>      \n      <div class="siret item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('orders.siret') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n              <div class="tool-item">\n                <div class="to-vat" title="' +
((__t = ( _.i18n('common.siret-to-vat.title') )) == null ? '' : __t) +
'">' +
((__t = ( _.i18n('common.siret-to-vat') )) == null ? '' : __t) +
'</div>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div> \n      <div class="country item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.country') )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="value"></div>\n      </div>  \n      <div class="delivery-address item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.deliveryAddress') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item" title="' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'">\n                <svg class="btn-edit-del-address"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>\n              </div>\n              <div class="tool-item" title="' +
((__t = ( _.i18n('common.copyToClipboard') )) == null ? '' : __t) +
'">\n                <svg class="btn-copy-delivery-address" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n              <div class="tool-item" title="' +
((__t = ( _.i18n('orders.deliveryAddress.copyFromClient') )) == null ? '' : __t) +
'">\n                <svg class="from-client" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M128 64c0-35.3 28.7-64 64-64H352V128c0 17.7 14.3 32 32 32H512V448c0 35.3-28.7 64-64 64H192c-35.3 0-64-28.7-64-64V336H302.1l-39 39c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l39 39H128V64zm0 224v48H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H128zM512 128H384V0L512 128z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>   \n      <div class="invoice-address item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('endUsersDetail.invoiceAddress') )) == null ? '' : __t) +
'</div>\n          <div class="tools-ctn">\n            <div class="tools">\n              <div class="tool-item">\n                <svg class="btn-copy-invoice-address" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#929292" d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="value"></div>\n      </div>\n      <div class="webshop item">\n        <div class="libelle">\n          <div class="text">' +
((__t = ( _.i18n('common.webshop') )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="value"></div>\n      </div>\n    </div>\n    <div class="column image-col">\n      <div class="images-region"></div>       \n    </div>       \n  </div>  \n  <div class="bottom">\n    <div class="comments-region"></div>\n  </div>\n</div>';

}
return __p
};});

