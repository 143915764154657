define('webshopProductsDetailBodyImagesListImageView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'galleryView',
  'template!webshopProductsDetailBodyImagesListImageView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  GalleryView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-images-list-image-view',

    ui: {
      imageContainer: '.image',
      zoomButton: '.zoom-button',
      deleteButton: '.delete-button'
    },

    events: {
      'click @ui.imageContainer': 'onClick',
      'click @ui.zoomButton': 'onZoom',
      'click @ui.deleteButton': 'onDelete'
    },

    serializeData: function () {
      var data = {};

      var url = Settings.configValue('webshop.imagesUrl');
      data.src =  url + '/' + this.model.get('previewUrl');
      return data;
    },

    onDelete: function () {
      this.options.product.deleteImage(this.model.get('id')).done(_.bind(function () {
        this.trigger('item:deleted');
      }, this));
    },

    onZoom: function () {
      var urls = this.model.get('url');
      if(urls.length === 0)
        return;

      var url= 'https://www.zaprinta.fr/imgs/' + this.model.get('url');

      GalleryView.prototype.open(
        'webshop.product.gallery.' + url,
        _.i18n('webshop.product.gallery.title') + ' | ' + url,
        new Backbone.Model({urls: [url]}));
    }
  });
});
