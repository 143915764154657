define('webshopCategoriesTreeView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'modalsConfirmationView',
  'webshopCategoriesDetailView',
  'template!webshopCategoriesTreeView',
  'entities/webshop/categories',
  'jstree'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  ConfirmationView,
  DetailView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-tree-view',

    ui: {
      tree: '.tree',
      openAll: '.open-all',
      closeAll: '.close-all',
      openDetail: '.open-detail'
    },

    events: {
      'click @ui.openAll': 'openAll',
      'click @ui.openDetail': 'openDetail',
      'click @ui.closeAll': 'closeAll'
    },

    regions: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region'      
    },

    initialize: function () {
      this.categories = [];
      this.selectedNode = null;
    },

    onRender: function () {
      this.ui.tree.jstree({
        'core': {
          'data': _.bind(this.fetch, this),
          'check_callback': true,
          'multiple' : false
        },
        'plugins': ['dnd']
      }).bind('move_node.jstree', _.bind((function (e, data) {

        ConfirmationView.prototype.open({
          text: _.i18n('orders.categories.tree.move.confirmation').replace('%s', data.node.text),
          onConfirm: _.bind(function() {
            var oldParentId = data.old_parent !== '#' ? data.old_parent : '1'; // jshint ignore:line
            var newParentId = data.parent !== '#' ? data.parent : '1';
            var refId = this.findRefCategoryId(data);

            this.move(data.node.id, oldParentId, newParentId, refId).done(_.bind(function () {
            }, this)).fail(_.bind(function () {
              data.instance.refresh();
            }, this));
          },this),
          onCancel: _.bind(function() {
            data.instance.refresh();
          }, this)
        });

      }), this)).on('select_node.jstree', _.bind(function(e, data) {
        this.selectedNode = data.node;
      }, this)).on('dblclick.jstree', _.bind(function () {
        var selectedNode = this.ui.tree.jstree('get_selected', true);
        if (selectedNode.length > 0) {
          DetailView.prototype.open(selectedNode[0].id);
        }
      }, this));
    },

    findRefCategoryId: function (data) {
      var parentNode = this.ui.tree.jstree(true).get_node(data.parent); // jshint ignore:line

      var id = '';
      _.each(parentNode.children, function (childId, index) {
        if(index === data.position+1)
          id = childId;
      }, this);

      return id;
    },

    /*checkDnd: function (operation, node) {
      return true;
    },*/

    fetch: function (node, cb) {
      var params = {};
      params.id = 1;
      params.locale = Settings.get('lang');

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url:  App.request('webshop.categories.tree', params),
        success: _.bind(function (categories) {
          _.each(categories, function (category) {
            this.formatItem(category);
          }, this);

          this.categories = categories;
          return cb(categories);
        }, this)
      });
    },

    formatItem: function (item) {
      if (item.status === 1)
        //category.icon = 'app/styles/images/card_colorization.png';
        item.text = '<strong>' + item.text + '</strong>';
      else
        item.text = item.text + '<svg class="disabled-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>'; // jshint ignore:line


      _.each(item.children, function (child) {
        this.formatItem(child);
      }, this);
    },

    move: function (categoryId, oldParentId, newParentId, refId) {
      var defer = $.Deferred();

      var params = {
        id: categoryId,
        oldParentId: oldParentId,
        newParentId: newParentId,
        refId: refId
      };

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'webshop/categories/tree/move'),
        data: JSON.stringify(params),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data.responseText.message);
        }
      });
      return defer.promise();
    },

    openAll: function () {
      this.ui.tree.jstree('open_all');
    },

    closeAll: function () {
        this.ui.tree.jstree('close_all');
    },

    openDetail: function () {
      var selectedNode = this.ui.tree.jstree('get_selected', true);
      if(selectedNode.length > 0) {
        DetailView.prototype.open(selectedNode[0].id);
      }
    }
  });
});

