define('ordersBoardCardsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'bootbox',
  'settings',
  'gmailUtils',
  'moment',
  'notificationCenter',
  'ordersController',
  'ordersBoardCardView',
  'ordersBoardGroupView',
  'template!ordersBoardCardsView',
  'entities/orders'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  bootbox,
  Settings,
  GmailUtils,
  moment,
  NotificationCenter,
  Controller,
  CardView,
  GroupView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childViewContainer: '.cards-container',
    className: 'orders-board-kanban-cards-view',

    attributes: function () {
      return {
        'data-id': this.status//,
        //'data-params': encodeURI(this.model.get('params'))
      };
    },

    childViewOptions: function () {
      return {
        //users: this.options.users,
        statuses: this.options.statuses,
        status: this.status,
        type: this.options.type,
        boardView: this.options.boardView,
        kanbanView: this.options.kanbanView,
        mailParams: this.mailParams,
        cards: this
      };
    },

    childEvents: {
      'onDrag': function () {
        this.trigger('onDrag');
      },
      'onStopDrag': function () {
        this.trigger('onStopDrag');
      }
    },

    getChildView: function (model) {
      if(model.get('orders'))
        return GroupView;
      else
        return CardView;
    },

    ui: {
      title: '.title .text',
      count: '.title .count',
      closedFolderIcon: '.closed-folder-icon',
      openedFolderIcon: '.opened-folder-icon',
      showOnlyMyne: '.show-only-myne',
      quantity: '.quantity',
      price: '.price',
      folder: '.folder',
      filter: '.filter',
      cardsContainer: '.cards-container',
      loading: '.loading',
      lazyArea: '.lazy-area',
      sort: '.sort',
      selectAll: '.select-all',
      sortPanel: '.sort-panel',
      sortItem: '.sort-panel .item',
      resetSort: '.reset-sort-button',
      closeSort: '.sort-panel .close-button'
    },

    regions: {
      sortRegion: '.sort-region'
    },

    events: {
      'dblclick @ui.folder': 'openFolder',
      'click @ui.sort': 'onSort',
      'change @ui.showOnlyMyne': 'onShowOnlyMyneChange',
      'click @ui.sortPanel': 'onFilter',
      'click @ui.sortItem': 'onSortSelect',
      'click @ui.resetSort': 'resetSort',
      'click @ui.closeSort': 'onCloseSortPanel',
      'click @ui.selectAll': 'selectAll'
    },

    initialize: function () {
      this.SortedCollection = Backbone.Collection.extend({
        comparator: _.bind(function (a, b) {

          if (this.sortData[this.model.get('status')].field !== '') {
            var dir = this.sortData[this.model.get('status')].dir;
            var field = this.sortData[this.model.get('status')].field;

            if(field === 'priority') {
              if(dir === 'ASC')
                if(a.get('priority') > b.get('priority')) return 1;
                else return -1;
              else
              if(a.get('priority') < b.get('priority')) return 1;
              else return -1;
            }

            if(field === 'price') {
              if(dir === 'ASC')
                if(a.get('price') > b.get('price')) return 1;
                else return -1;
              else
              if(a.get('price') < b.get('price')) return 1;
              else return -1;
            }

            if(field === 'deadlineDate') {
              if(dir === 'ASC')
                if(new Date(a.get('deadlineDate')) > new Date(b.get('deadlineDate'))) return 1;
                else return -1;
              else
                if(new Date(a.get('deadlineDate')) < new Date(b.get('deadlineDate'))) return 1;
                else return -1;
            }

            if(field === 'expShippingDate') {
              if(dir === 'ASC')
                if(new Date(a.get('expShippingDate')) > new Date(b.get('expShippingDate'))) return 1;
                else return -1;
              else
                if(new Date(a.get('expShippingDate')) < new Date(b.get('expShippingDate'))) return 1;
                else return -1;
            }

            if(field === 'payedByCustomer') {
              if(dir === 'ASC')
                if(a.get('payedByCustomer') > b.get('payedByCustomer')) return 1;
                else return -1;
              else
                if(a.get('payedByCustomer') < b.get('payedByCustomer')) return 1;
                else return -1;
            }

            if(field === 'date') {
              if(dir === 'ASC')
                if(new Date(a.get('createDate')) > new Date(b.get('createDate'))) return 1;
                else return -1;
              else
                if(new Date(a.get('createDate')) < new Date(b.get('createDate'))) return 1;
                else return -1;
            }
          }

          return 1;
        }, this)
      });

      this.collection = new this.SortedCollection();
      this.count = 0;
      this.status = this.model.get('statusConst');
      this.mailParams = null;
      this.filters = [];
      this.fetchRequest = null;
      this.fetchCountRequest = null;

      //-------------

      var sortStr = Settings.get('boardSort');

      if (sortStr && sortStr !== '')
        this.sortData = JSON.parse(sortStr);

      if (!this.sortData)
        this.sortData = {};

      if (!this.sortData[this.model.get('status')])
        this.sortData[this.model.get('status')] = {
          field: '',
          dir: ''
        };

      //-------------

      var params = this.model.get('params');

      if (params && params[0] === '{') {
        try {
          this.mailParams = JSON.parse(params);
        } catch (err) {
        }
      }
    },

    onShow: function () {
      NotificationCenter.getInstance().addListener(this, NotificationCenter.events.CHANGE_INFO_ORDER, _.bind(function (response) {
        if(response && response.content && response.content.poi) {
          var order = App.request('order:model', App.request('order:parse-list-data', response.content));

          if (order.get('status') !== this.status) {
            this.removeOrder(order);
            return;
          }

          if(order.get('commandType') !== this.options.type) {
            this.removeOrder(order);
            return;
          }

          if(order.get('archived') === true) {
            this.removeOrder(order);
            return;
          }

          var params = _.extend({
            status: this.status,
            types: this.options.type,
            purchaseOrderId: order.get('purchaseOrderId')
          }, this.getFiltersParams());

          $.ajax({
            type: 'GET',
            url: App.request('order:get-orders-count', params),
            success: _.bind(function (count) {
              if (count.totalCount > 0)
                this.addOrder(order);
              else
                this.removeOrder(order);
            }, this)
          });
        }
      }, this));

      this.options.filtersView.on('change', _.bind(this.filtersChange, this));

      this.observer = new window.IntersectionObserver(_.bind(function (aEntries) {
        if (aEntries.length > 0 && aEntries[0].isIntersecting)
          this.fetch();
      }, this), {
        root: this.ui.cardsContainer[0],
        rootMargin: '100px',
        threshold: 0.5
      });

      //-------------

      $(window).click(_.bind(function (e) {
        if ($(e.target).closest('.sort-panel').length === 0)
          this.hideSortPanel();
      }, this));

      $(document).keyup(_.bind(function (e) {
        if (e.which === 27) this.options.kanbanView.hideSortPanels();
      }, this));

      //-------------

      this.fetch();
    },

    resortView: function() {
      console.log('resortView');
    },

    onRender: function () {
      this.renderSort();
      this.renderSortable();

      //-------------------
      var title = this.model.get('status');
      if (this.model.get('status').indexOf(' / ') !== -1)
        title = this.model.get('status').split(' / ')[1];
      this.ui.title.html(title);

      //-------------------
      this.ui.folder.hide();
      this.ui.openedFolderIcon.hide();
      this.ui.closedFolderIcon.hide();

      if (this.model.get('isSubDirectory')) {
        this.ui.openedFolderIcon.show();
        this.ui.folder.css('display', 'flex');
      } else {
        if (this.model.get('childStatus') && this.model.get('childStatus').length > 0) {
          this.ui.closedFolderIcon.show();
          this.ui.folder.css('display', 'flex');
        }
      }

      //-------------------
      if (Settings.get('showOnlyMine' + this.status, true) === 'true')
        this.ui.showOnlyMyne.attr('checked', true);
      else
        this.ui.showOnlyMyne.attr('checked', false);

      //-------------------
      if (Settings.configValue('config.board.header.hideQuantity') === true)
        this.ui.quantity.hide();
      else
        this.ui.quantity.show();
    },

    renderSort: function () {
      if (this.sortData[this.model.get('status')].field !== '')
        this.ui.sort.addClass('active');
      else
        this.ui.sort.removeClass('active');

      this.options.boardView.headerView.renderUnSortButton(this.options.kanbanView.getSortedViews());
    },

    fetch: function () {
      var params = _.extend({
        status: this.status
      }, this.getFiltersParams());

      if (this.model.get('isSubDirectory'))
        params.excludeSubLevel = true;

      if (Settings.get('showOnlyMine' + this.status, true) === 'true')
        params.showOnlyMine = true;

      params.first = 0;
      _.each(this.collection.models, _.bind(function (model) {
        if(model.get('orders'))
          params.first += model.get('orders').length;
        else
          params.first++;
      }, this));
      params.pageSize = 20;

      if (this.sortData[this.model.get('status')].field !== '') {
        params.sord = this.sortData[this.model.get('status')].dir;
        params.sidx = this.sortData[this.model.get('status')].field;
      }

      if(this.options.type === 'offer')
        params.types = 'offer';

      this.observer.disconnect();

      if (this.collection.length === 0) {
        this.ui.loading.addClass('activated');
        this.ui.cardsContainer.removeClass('activated');
      }

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('order:get-orders', params),
        success: _.bind(function (items) {
          _.each(items, _.bind(function(item) {
            var order = App.request('order:model', App.request('order:parse-list-data', item));
            this.addOrder(order);
          }, this));

          this.sort();

          if (items.length > 0) {
            var view = this.children.last();
            if (view)
              this.observer.observe(view.$el[0]);
          }

          if (!this.isDestroyed) {
            this.ui.loading.removeClass('activated');
            this.ui.cardsContainer.addClass('activated');
          }
        }, this)
      });

      this.fetchCount();
    },

    sort: function () {
      this.collection.sort();
      _.each(this.collection.models, _.bind(function (model) {
        if(model.get('orders'))
          model.get('orders').sort();
      }, this));
    },

    removeOrder: function (order) {
      this.children.each(_.bind(function(view) {
        if(view.model.get('orders'))
          view.removeOrder(order);
      }, this));
      this.collection.remove(order);
    },

    addOrder: function (order) {
      if (this.getOrder(order.get('purchaseOrderId')))
        return;

      var group = null;
      var position = 0;

      _.each(this.collection.models, _.bind(function (model) {
        if(model.get('endUserId') && model.get('endUserId') === order.get('endUserId') && model.get('orders'))
          group = model;
      }, this));

      if(!group) {
        var unGroupedOrder = null;

        _.each(this.collection.models, _.bind(function (model) {
          if(model.get('endUserId') &&
            model.get('endUserId') === order.get('endUserId') &&
            model.get('purchaseOrderId') !== order.get('purchaseOrderId') &&
            !model.get('orders'))
            unGroupedOrder = model;
        }, this));

        if(unGroupedOrder) {
          group = App.request('group-model', {
            priority: 0,
            endUserId: order.get('endUserId'),
            minimized: !this.options.filtersView.hasFilter(),
            orders: new this.SortedCollection()
          });

          unGroupedOrder.set('grouped', true);
          unGroupedOrder.set('minimized', group.get('minimized'));

          group.get('orders').add(unGroupedOrder);

          this.collection.remove(unGroupedOrder);

          position = this.collection.models.length;
          if(order.get('position') >= 0)
            position = order.get('position');
          group.set('position', position);
          if (this.sortData[this.model.get('status')].field === '')
            this.collection.add(group, {at: position});
          else
            this.collection.add(group);
        }
      }

      if(group) {
        order.set('grouped', true);
        order.set('minimized', group.get('minimized'));
        group.add(order);
        group.trigger('change:orders');
      }
      else {
        order.set('grouped', false);
        position = this.collection.models.length;
        if(order.get('position') >= 0)
          position = order.get('position');
        order.set('position', position);

        if (this.sortData[this.model.get('status')].field === '')
          this.collection.add(order, {at: position});
        else
          this.collection.add(order);
      }
    },

    getOrder: function (purchaseOrderId) {
      var order = null;
      this.children.each(_.bind(function(view) {
        if(view.model.get('orders')) {
          order = view.getOrder(purchaseOrderId);
        } else {
          if(view.model.get('purchaseOrderId') === purchaseOrderId)
            order = view.model;
        }
      }, this));

      return order;
    },

    fetchCount: function () {
      var params = _.extend({
        status: this.status,
        types: this.options.type
      }, this.getFiltersParams());

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('order:get-orders-count', params),
        success: _.bind(function (count) {
          if (!this.isDestroyed) {
            this.count = count.totalCount;
            this.ui.count.html(this.count);
            this.ui.count.addClass('visible');

            var priceLocale = 'fr-BE';
            var configPriceLocale = Settings.configValue('priceLocale');
            if (configPriceLocale)
              priceLocale = configPriceLocale;

            var sPrice = new Intl.NumberFormat(priceLocale).format(count.totalPrice);
            this.ui.price.html('€ ' + sPrice);
            this.ui.quantity.html(count.totalQuantity + ' ' + _.i18n('common.pcs'));
          }
        }, this)
      });
    },

    getFiltersParams: function() {
      var params = {};

      if(this.options.type === 'offer')
        params.types = 'offer';

      var filters = this.options.filtersView.model;

      _.each(filters.attributes, function (value, key) {
        if(key !== 'quantityFrom' &&
            key !== 'quantityTo' &&
            key !== 'priceFrom' &&
            key !== 'priceTo' &&
            key !== 'dateFrom' &&
            key !== 'dateTo') {
          if(value !== '')
            params[key] = value;
        }
      }, this);

      if(filters.get('quantityFrom') !== '' || filters.get('quantityTo') !== '') {
        var quantityFrom = filters.get('quantityFrom');
        var quantityTo = filters.get('quantityTo');

        if(quantityFrom === '')
          quantityFrom = 0;
        if(quantityTo === '')
          quantityTo = 999999999999;

        params.quantity = quantityFrom + '-' + quantityTo;
      }

      if(filters.get('priceFrom') !== '' || filters.get('priceTo') !== '') {
        var priceFrom = filters.get('priceFrom');
        var priceTo = filters.get('priceTo');

        if(priceFrom === '')
          priceFrom = 0;
        if(priceTo === '')
          priceTo = 999999999999;

        params.price = priceFrom + '-' + priceTo;
      }

      if(filters.get('dateFrom') !== '' && filters.get('dateTo') !== '') {
        params.dateFrom = filters.get('dateFrom');
        params.dateTo = filters.get('dateTo');
      }

      return params;
    },

    filtersChange: function () {
      this.collection.reset();
      this.fetch();
    },

    openFolder: function () {
      var url = '';

      if (this.options.type === 'command')
        url = 'orders_kanban';
      if (this.options.type === 'offer')
        url = 'offers_kanban';

      if (!this.model.get('isSubDirectory') && this.model.get('childStatus') && this.model.get('childStatus').length > 0)
        App.navigate(url + '/' + this.status, {trigger: true});
      else
        App.navigate(url, {trigger: true});
    },

    onSort: function (e) {
      e.stopPropagation();

      if (this.isDestroyed)
        return;

      this.ui.sortItem.attr('data-dir', '');
      this.ui.sortItem.removeClass('selected');

      var sortField = this.sortData[this.model.get('status')].field;
      var dir = this.sortData[this.model.get('status')].dir;

      this.ui.sortPanel.find('.item[data-id="' + sortField + '"]').attr('data-dir', dir);

      this.options.kanbanView.hideSortPanels();
      this.ui.sortPanel.css('display', 'flex');
    },

    hideSortPanel: function () {
      if (this.isDestroyed)
        return;

      this.ui.sortPanel.hide();
    },

    onShowOnlyMyneChange: function (event) {
      Settings.set('showOnlyMine' + this.status, event.target.checked, true);
      this.collection.reset();
      this.fetch();
    },

    renderSortable: function () {

      this.ui.cardsContainer.sortable({
        connectWith: '.cards-container',
        placeholder: 'card-placeholder',
        opacity: 0.8,
        cursor: 'move',
        start: _.bind(function (event, ui) {
          this.trigger('onDrag');

          this.options.kanbanView.multiSelect = [];

          var view = null;
          _.each(this.children._views, function (v) {
            if(v.$el[0] === ui.item[0])
              view = v;
          }, this);

          if(!view)
            return;

          this.options.kanbanView.multiSelect.push(view);

          this.children.each(_.bind(function(selectedView) {
            if(selectedView.isSelected() && selectedView.cid !== view.cid) {
              this.options.kanbanView.multiSelect.push(selectedView);
              selectedView.$el.hide();
            }
          }, this));
        }, this),
        receive: _.bind(function (event, ui) {
          var position = ui.item.index();
          ui.item.remove();
          this.trigger('onStopDrag');

          var orders = [];

          _.each(this.options.kanbanView.multiSelect, _.bind(function (view) {
            if(view.type === 'card')
              orders.push(view.model);
            if(view.type === 'group')
              orders = orders.concat(view.model.get('orders').models);
          }, this));

          _.each(orders, _.bind(function (order) {
            var oldStatus = order.get('status');
            order.updateStatus(this.status).done(_.bind(function () {
              try {
                this.options.kanbanView.getStatusView(oldStatus).fetchCount();
                this.options.kanbanView.getStatusView(this.status).fetchCount();
              } catch (e) {
                console.log(e);
              }
            }, this));

            try {
              if(order.get('status') === this.status)
                this.options.kanbanView.getStatusView(this.status).removeOrder(order);

              order.set('status', this.status);
              order.set('position', position);
              this.options.kanbanView.getStatusView(this.status).addOrder(order);
            } catch (e) {
              console.log(e);
            }
          }, this));

          //-------------------
         /* var usersOrders = [];

          _.each(orders, _.bind(function (order) {
            var userOrder = null;
            _.each(usersOrders, _.bind(function (uo) {
              if (uo.userId === order.get('endUserId'))
                userOrder = uo;
            }, this));

            if (userOrder === null) {
              userOrder = {
                userId: order.get('endUserId'),
                orders: []
              };
              usersOrders.push(userOrder);
            }

            userOrder.orders.push(order);
          }, this));*/

          Controller.sendMail(orders, this.mailParams);

          //-------------------
          this.options.kanbanView.unSelectAll();

        }, this),
        stop: _.bind(function (event, ui) {
          this.trigger('onStopDrag');

          var view = null;
          _.each(this.children._views, function (v) {
            if(v.$el[0] === ui.item[0])
              view = v;
          }, this);

          if(view)
            view.model.set('position', ui.item.index());

          _.each(this.options.kanbanView.multiSelect, _.bind(function (view) {
            view.$el.css('display', 'flex');
          }, this));
        }, this)
      });
    },

    getSelectedCards: function () {
      var views = [];

      _.each(this.children._views, function (view) {
        if(view.model.get('orders'))
          views = views.concat(view.cardsView.getSelectedCards());
        else
        if(view.isSelected())
          views.push(view);
      });

      return views;
    },

    onSortSelect: function (event) {
      if (this.isDestroyed)
        return;

      if ($(event.currentTarget).attr('data-dir') === '') {
        this.ui.sortItem.attr('data-dir', '');
        this.ui.sortItem.removeClass('selected');
        $(event.currentTarget).attr('data-dir', 'DESC');
        $(event.currentTarget).addClass('selected');
        this.sortData[this.model.get('status')].field = $(event.currentTarget).attr('data-id');
        this.sortData[this.model.get('status')].dir = 'DESC';

      } else if ($(event.currentTarget).attr('data-dir') === 'DESC') {
        $(event.currentTarget).attr('data-dir', 'ASC');
        $(event.currentTarget).addClass('selected');
        this.sortData[this.model.get('status')].dir = 'ASC';

      } else if ($(event.currentTarget).attr('data-dir') === 'ASC') {
        $(event.currentTarget).attr('data-dir', '');
        $(event.currentTarget).removeClass('selected');
        this.sortData[this.model.get('status')].field = '';
        this.sortData[this.model.get('status')].dir = '';
      }

      Settings.set('boardSort', JSON.stringify(this.sortData));

      this.renderSort();
      this.collection.reset();
      this.fetch();
    },

    onCloseSortPanel: function () {
      this.hideSortPanel();
    },

    unSelectAll: function () {
      _.each(this.children._views, function (view) {
        if(view.model.get('orders'))
          view.unSelectAll();
        else
          view.unSelect();
      });
    },

    selectAll: function () {
      _.each(this.children._views, function (view) {
        view.select();
      });
    },

    resetSort: function () {
      if (this.isDestroyed)
        return;

      if(this.sortData[this.model.get('status')].field === '')
        return;

      this.sortData[this.model.get('status')].field = '';
      this.sortData[this.model.get('status')].dir = '';

      this.ui.sortItem.attr('data-dir', '');
      this.ui.sortItem.removeClass('selected');

      Settings.set('boardSort', JSON.stringify(this.sortData));

      this.renderSort();
      this.collection.reset();
      this.fetch();
    }
  });
});

